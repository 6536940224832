// components/PrivacyPolicy.js
import React, { useEffect } from 'react';
import Footer from './Footer';

const PrivacyPolicy = () => {
    useEffect(() => {
        const scriptId = 'termly-jssdk';
        
        if (!document.getElementById(scriptId)) {
            const script = document.createElement('script');
            script.id = scriptId;
            script.src = "https://app.termly.io/embed-policy.min.js";
            document.body.appendChild(script);
        }
    }, []);

    return (
        <div>
            <div name="termly-embed" data-id="59cc73e8-bf43-468d-ac53-a61d4046a282"></div>
            
        </div>
    );
};

export default PrivacyPolicy;
