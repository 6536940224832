// UnsavedChangesModal.js
import React from 'react';

const UnsavedChangesModal = ({ show, onClose }) => {
    if (!show) return null;

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <p>Make sure to press submit on any changes.</p>
                <button className="button" onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default UnsavedChangesModal;
