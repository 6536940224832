import React from 'react';
import styles from './Integrations.module.css';
import slackLogo from '../assets/logos/slack-logo.png';
import clickupLogo from '../assets/logos/clickup-logo.png';
import googleLogo from '../assets/logos/google-logo.png';

const Integrations = () => {
    return (
        <div className={styles.connectedApps}>
            <h2>Connected Apps (Just for show for now)</h2>
            <div className={styles.appCard}>
                <div className={styles.appHeader}>
                    <div className={styles.appInfo}>
                        <img src={slackLogo} alt="Slack" />
                        <span className={styles.appName}>Slack</span>
                    </div>
                    <span className={`${styles.status} ${styles.available}`}><span className={styles.dot}></span>Available & Working</span>
                    <button className={styles.detailsButton} aria-expanded="false">
                        View Details
                        <span className="material-icons">keyboard_arrow_down</span>
                    </button>
                </div>
            </div>
            <div className={styles.appCard}>
                <div className={styles.appHeader}>
                    <div className={styles.appInfo}>
                        <img src={clickupLogo} alt="Clickup" />
                        <span className={styles.appName}>Clickup</span>
                    </div>
                    <span className={`${styles.status} ${styles.error}`}><span className={styles.dot}></span>Error - Invalid API Key</span>
                    <button className={styles.detailsButton} aria-expanded="false">
                        View Details
                        <span className="material-icons">keyboard_arrow_down</span>
                    </button>
                </div>
            </div>
            <div className={styles.appCard}>
                <div className={styles.appHeader}>
                    <div className={styles.appInfo}>
                        <img src={googleLogo} alt="Google LSA" />
                        <span className={styles.appName}>Google LSA</span>
                    </div>
                    <span className={`${styles.status} ${styles.available}`}><span className={styles.dot}></span>Available & Working</span>
                    <button className={styles.detailsButton} aria-expanded="false">
                        Hide Details
                        <span className="material-icons">keyboard_arrow_up</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Integrations;
