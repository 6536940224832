import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './NavBar.module.css';
import logo from '../assets/logos/FerociousMEDIALogo_H.png';

const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [user, setUser] = useState({});
    const navigate = useNavigate();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    const handleScroll = () => {
        const scrollTop = window.scrollY;
        if (scrollTop > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
        navigate('/admin/login');
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser) {
            setUser(storedUser);
        }
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <nav className={`${styles.navBar} ${isScrolled ? styles.scrolled : ''}`}>
                <div className={styles.navBarContent}>
                    <button className={styles.hamburger} onClick={toggleMenu}>
                        &#9776;
                    </button>
                    <img src={logo} alt="Logo" className={styles.logo} />
                </div>
            </nav>
            <div className={`${styles.sideNav} ${isOpen ? styles.open : ''}`}>
                <button className={styles.closeBtn} onClick={closeMenu}>&times;</button>
                <div className={styles.sideNavHeader}>
                    <div className={styles.profilePic}>
                        
                        {user.name &&(
                            <p className={styles.nameFirstChar}>{user.name[0]}</p>
                        )}
                    </div>
                    <div className={styles.profileInfo}>
                        <h3>{user.name}</h3>
                        <p>{user.email}</p>
                        <p>{user.userType}</p>
                    </div>
                </div>
                <div className={styles.sideNavLinks}>
                    <h4>Main</h4>
                    <Link to="/admin" onClick={closeMenu}>
                        <i className="material-icons">add</i>
                        <span>Add New Client Portal</span>
                    </Link>
                    <Link to="/existing-portals" onClick={closeMenu}>
                        <i className="material-icons">view_list</i>
                        <span>Existing Client Portals</span>
                    </Link>
                    <h4>Tools</h4>
                    <Link to="/admin/add-users" onClick={closeMenu}>
                        <i className="material-icons">person_add</i>
                        <span>Add Ferocious Users</span>
                    </Link>
                    <Link to="/team-email" onClick={closeMenu}>
                        <i className="material-icons">mail</i>
                        <span>Send Team Intro Email</span>
                    </Link>
                    {user.userType === 'Super Admin' && (
                        <>
                            <Link to="/super-admin-dashboard" onClick={closeMenu}>
                                <i className="material-icons">dashboard</i>
                                <span>Super Admin Dashboard</span>
                            </Link>
                            <Link to="/activity-log" onClick={closeMenu}>
                                <i className="material-icons">history</i>
                                <span>Activity Log</span>
                            </Link>
                        </>
                    )}
                    <Link to="/admin/login" onClick={() => {handleLogout(); closeMenu();}}>
                        <i className="material-icons">exit_to_app</i>
                        <span>Log Out</span>
                    </Link>
                </div>
            </div>

            {isOpen && <div className={styles.overlay} onClick={closeMenu}></div>}
        </>
    );
};

export default NavBar;
