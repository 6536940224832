import axios from 'axios';

const api = axios.create({
     baseURL: 'https://onboarding-crm-06619bb63b87.herokuapp.com/'
    // baseURL: 'http://localhost:3000'
});

const BASE_URL = 'https://onboarding-crm-06619bb63b87.herokuapp.com';
// const BASE_URL = 'https://my-crm-app-be05fb8e206a.herokuapp.com';
//  const BASE_URL = 'http://localhost:3000';


export const createClient = (data) => api.post('/admin/clients', data);
export const getClientData = (url) => api.get(`/client/${url}`);
export const uploadDocument = (data, onUploadProgress) => api.post('/client/upload-document', data, {
    headers: {
        'Content-Type': 'multipart/form-data'
    },
    onUploadProgress
});
export const getAllClients = () => api.get('/admin/clients');
export const deleteClient = (clientId) => api.delete(`/admin/clients/${clientId}`);
export const submitText = (data) => api.post('/client/submit-text', data);
export const deleteSection = (clientId, sectionName) => api.delete(`/admin/clients/${clientId}/section/${sectionName}`);
export const deleteSectionItem = (clientId, itemId) => api.delete(`/admin/clients/${clientId}/item/${itemId}`);
export const addSectionItem = (clientId, data) => api.post(`/admin/clients/${clientId}/item`, data);
export const getUsers = () => api.get('/users');
export const addUser = (data) => api.post('/users', data);
export const deleteUser = (userId) => api.delete(`/users/${userId}`);
export const updateUserType = (id, data) => api.put(`/users/${id}`, data);
export const getClientUsers = (clientId) => api.get(`/client/${clientId}/users`);
export const submitBusinessHours = (data) => api.post('/client/submit-business-hours', data);
export const submitMultipleChoice = (data) => api.post('/client/submit-multiple-choice', data);
export const submitCheckboxes = (data) => api.post('/client/submit-checkboxes', data);
export const submitAdSchedule = (data) => api.post('/client/submit-ad-schedule', data);
export const submitChecklistText  = (data) => api.post('/client/submit-checklist-text', data);

export const removeCompanyFromUser = (clientId, userId) => api.delete(`/client/${clientId}/users/${userId}/company`);
export const addClientUser = (clientId, data) => api.post(`/client/${clientId}/users`, data);
export const checkUserExists = (email) => api.post('/check-user', { email });
export const verifyUser = (token) => api.post('/verifyUser', { token });
export const checkAuth = (email) => api.get('/auth/check', { params: { email } });
export const checkClientAuth = (email) => api.get('/auth/client/check', { params: { email } });
export const getUserById = (userId) => api.get(`/user/${userId}`);
export const getCompanyList = (email) => api.get('/client/companies', { params: { email } });
export const getClientView = (url) => api.get(`/client/${url}`);
export const getClientById = (clientId) => api.get(`/client/id/${clientId}`);


// New functions to get options for project managers and account strategists
export const getOptions = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/admin/options`);
        return response.data;
    } catch (error) {
        console.error('Error fetching options:', error);
        throw error;
    }
};
export const getDefaultChecklistItems = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/admin/default-checklist-items`);
        return response.data;
    } catch (error) {
        console.error('Error fetching default checklist items:', error);
        throw error;
    }
};
export const updateClient = (clientId, data) => api.put(`/admin/clients/${clientId}`, data);
export const deleteChecklistItem = (checklistId) => api.delete(`/admin/checklist/${checklistId}`);
export const addChecklistItem = (clientId, itemData) => api.post(`/admin/client/${clientId}/checklist`, itemData);
export const logClientUserVisit = (data) => api.post('/log-login', data);
export const deleteContactInfo = (data) => api.post('/client/delete-contact-info', data);
export const addContactInfo = (data) => api.post('/client/add-contact-info', data);
export const submitMultipleText = (data) => api.post('/client/submit-multiple-text', data);

export const updateItemName = async (data) => {
    try {
        const response = await api.put(`/admin/update-item-name/${data.checklist_id}`, data);
        return response.data;
    } catch (error) {
        console.error('Error updating item name:', error);
        throw error;
    }
};

// Function to delete a document
export const deleteDocument = (documentId) => api.delete(`/client/delete-document/${documentId}`);


export const updateSectionName = async ({ oldSectionName, newSectionName, dueDate, client_id }) => {
    return api.put('/admin/update-section-name', { oldSectionName, newSectionName, dueDate, client_id });
};

export const createActivityLog = (data) => api.post('/activity-log', data);
export const getActivityLogs = () => api.get('/activity-log');

export const getClientLogs = (clientId) => api.get(`/logs/${clientId}`);
export const addClientLog = (data) => api.post('/logs', data);

export const fetchCompanyId = async (url) => {
    try {
        const response = await api.get(`/client/url/${url}`);
        return response.data.client._id;
    } catch (error) {
        console.error('Error fetching company ID:', error);
        throw error;
    }
};

export const updateItemDescription = async (data) => {
    try {
        const response = await api.put(`/admin/update-item-description/${data.checklist_id}`, data);
        return response.data;
    } catch (error) {
        console.error('Error updating item name:', error);
        throw error;
    }
};

export const updateChecklistItemName = async (clientId, checklistId, data) => {
    try {
        const response = await axios.put(`/clients/${clientId}/checklists/${checklistId}`, data);
        return response.data;
    } catch (error) {
        console.error('Error updating checklist item name:', error);
        throw error;
    }
};

export const saveDefaultChecklistItems = async (sections) => {
    try {
        const response = await axios.put(`${BASE_URL}/admin/default-checklist-items`, sections);
        return response.data;
    } catch (error) {
        console.error('Error saving default checklist items:', error);
        throw error;
    }
};


// Route to get all team members
export const getTeamMembers = () => api.get('/admin/options/team-members');

// Route to add a new team member
export const addTeamMember = (data) => api.post('/admin/options/team-member', data);

// Route to update a team member
export const updateTeamMember = (id, data) => api.put(`/admin/options/team-member/${id}`, data);

// Route to delete a team member
export const deleteTeamMember = (id) => api.delete(`/admin/options/team-member/${id}`);


export const addEmailHistory = (clientId, data) => api.post(`/client/${clientId}/email-history`, data);
export const getEmailHistory = (clientId) => api.get(`/client/${clientId}/email-history`);

export const getSlackUsers = () => api.get(`/slack/users`);

export const getSlackChannels = () => api.get(`/slack/channels`);



export const createSlackChannelApi = async (channelName, userIds, clientName, distroEmail, folderURL) => {
    try {
        const response = await api.post('/slack/channel', { channelName, userIds, clientName, distroEmail, folderURL });
        return response.data;
    } catch (error) {
        console.error('Error creating Slack channel and inviting users:', error);
        throw error;
    }
};


export const postAndPinMessage = async (channelId, text) => {
    try {
        const response = await api.post('/slack/post-and-pin', { channelId, text });
        return response.data;
    } catch (error) {
        console.error('Error posting and pinning message:', error);
        throw error;
    }
};


export const submitConnectIframe = async (data) => {
    try {
        const response = await api.post('/client/submit-connect-iframe', data);
        return response.data;
    } catch (error) {
        console.error('Error submitting connect_iframe:', error);
        throw error;
    }
};





export const saveOptions = async (options) => {
    try {
        const response = await axios.put(`${BASE_URL}/admin/options`, options);
        return response.data;
    } catch (error) {
        console.error('Error saving options:', error);
        throw error;
    }
};


export const getEmails = async () => {
    try {
        const response = await api.get('/emails');
        return response.data;
    } catch (error) {
        console.error('Error fetching emails:', error);
        throw error;
    }
};


export const createDistroApi = async (groupEmail, groupName, groupDescription) => {
    try {
        const response = await api.post('/create-distro', { groupEmail, groupName, groupDescription });
        return response.data;
    } catch (error) {
        console.error('Error creating distribution list:', error);
        throw error;
    }
};

export const addMemberToDistro = async (groupEmail, memberEmail) => {
    try {
        const response = await api.post('/add-member-to-distro', { groupEmail, memberEmail });
        return response.data;
    } catch (error) {
        console.error('Error adding member to distribution list:', error);
        throw error;
    }
};

export const markSectionAsCompleted = async (clientId, sectionName, clientName, portalURL, distro) => {
    try {
        const encodedSectionName = encodeURIComponent(sectionName);
        const response = await api.put(`/client/${clientId}/section/${encodedSectionName}/complete`, {
            clientName,  // Send the clientName in the body
            portalURL,   // Send the portalURL in the body
            distro       // Send the distro in the body
        });
        return response.data;
    } catch (error) {
        console.error('Error marking section as completed:', error);
        throw error;
    }
};





export default api;
