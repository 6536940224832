// src/components/Footer.js
import React from 'react';
import styles from './Footer.module.css';
import logo from '../assets/logos/FerociousMEDIALogo_V_Knockout.png';

const Footer = ({ clientName }) => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className={styles.footer}>
            <img src={logo} alt="Ferocious Media Logo" className={styles.footerLogo} />
            <p className={styles.footerText}>&copy; {currentYear} Ferocious Media | Built by Ferocious specifically for {clientName}</p>
            <p className={styles.footerText}><a href='/privacy-policy'>Privacy Policy</a> | <a href='/terms-of-use'>Terms of Use</a></p>
        </footer>
    );
};

export default Footer;
