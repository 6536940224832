import React, { useEffect, useState } from 'react';
import NavBar from './NavBar'; // Import the NavBar component
import { getActivityLogs } from '../api'; // Import the new function
import styles from './ActivityLog.module.css';
import { FaChevronDown } from 'react-icons/fa'; // Import the chevron down icon

const ActivityLog = () => {
    const [activityLog, setActivityLog] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortConfig, setSortConfig] = useState(() => {
        const savedSortConfig = JSON.parse(localStorage.getItem('activityLogSortConfig'));
        return savedSortConfig || { key: 'date', direction: 'descending' };
    });

    useEffect(() => {
        const fetchActivityLog = async () => {
            try {
                const response = await getActivityLogs();
                setActivityLog(response.data);
            } catch (err) {
                setError('Failed to fetch activity log');
            } finally {
                setLoading(false);
            }
        };

        fetchActivityLog();
    }, []);

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        const newSortConfig = { key, direction };
        setSortConfig(newSortConfig);
        localStorage.setItem('activityLogSortConfig', JSON.stringify(newSortConfig));
    };

    const sortedActivityLog = React.useMemo(() => {
        let sortableLogs = [...activityLog];
        if (sortConfig.key !== '') {
            sortableLogs.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableLogs;
    }, [activityLog, sortConfig]);

    const getSortIconClass = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? styles.ascending : styles.descending;
        }
        return styles.descending; // Default state
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <>
            <NavBar /> {/* Add the NavBar component here */}
            <div className={styles.activityLogContainer}>
                <h1>Ferocious Activity Log</h1>
                <table className={styles.activityLogTable}>
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('user')}>
                                <div className={styles.columnHeader}>
                                    <span>User</span>
                                    <FaChevronDown className={`${styles.chevron} ${getSortIconClass('user')}`} />
                                </div>
                            </th>
                            <th onClick={() => handleSort('action')}>
                                <div className={styles.columnHeader}>
                                    <span>Action</span>
                                    <FaChevronDown className={`${styles.chevron} ${getSortIconClass('action')}`} />
                                </div>
                            </th>
                            <th onClick={() => handleSort('date')}>
                                <div className={styles.columnHeader}>
                                    <span>Date</span>
                                    <FaChevronDown className={`${styles.chevron} ${getSortIconClass('date')}`} />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedActivityLog.map((log, index) => (
                            <tr key={index}>
                                <td>{log.user}</td>
                                <td>{log.action}</td>
                                <td>{new Date(log.date).toLocaleString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default ActivityLog;
