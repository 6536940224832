import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCompanyList } from '../api'; // Import the function from api.js
import styles from './SelectCompany.module.css';
import logo from '../assets/logos/FerociousMEDIALogo_mark-01.png'; // Import the logo

const SelectCompany = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [companies, setCompanies] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const storedUser = JSON.parse(localStorage.getItem('user')) || JSON.parse(sessionStorage.getItem('user'));
                const email = storedUser?.email || location.state?.email;

                if (!email) {
                    setError('No email provided');
                    console.log('No email provided'); // Log if no email provided
                    return;
                }

                console.log('Fetching companies for email:', email); // Log the email being used for fetching companies
                const response = await getCompanyList(email);
                console.log('Response from server:', response); // Log the response from the server

                if (response.data && response.data.companies) {
                    setCompanies(response.data.companies);
                    console.log('Companies:', response.data.companies); // Log the companies received
                } else {
                    setError('Failed to load companies');
                    console.log('Failed to load companies'); // Log if companies failed to load
                }
            } catch (err) {
                setError('Failed to load companies');
                console.error('Error loading companies:', err); // Log any errors
            }
        };

        fetchCompanies();
    }, [location.state]);

    const handleCompanySelect = (url) => {
        navigate(`/client/${url}`);
    };

    if (error) {
        return <div className={styles.error}>{error}</div>;
    }

    return (
        <div className={styles.selectCompanyContainer}>
            <div className={styles.selectCompanyBox}>
                <img src={logo} alt="Ferocious Media" className={styles.logo} /> {/* Add the logo */}
                <h1 className={styles.selectCompanyTitle}>Select a Company</h1>
                <ul className={styles.companyList}>
                    {companies.map(company => (
                        <li key={company._id} className={styles.companyItem}>
                            <button onClick={() => handleCompanySelect(company.url)}>
                                {company.name}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default SelectCompany;
