import React from 'react';
import styles from './Header.module.css';
import circleImage from '../assets/Gradient.png';  // Adjust the path as needed
import dotArrayImage from '../assets/Dot_Array.png';  // Adjust the path as needed

const Header = ({ Title, SecondaryTitle }) => {
    return (
        <div className={styles.header}>
            <div className={styles.circle} style={{ backgroundImage: `url(${circleImage})` }}></div>
            <div className={styles.dotArray} style={{ backgroundImage: `url(${dotArrayImage})` }}></div>
            <div className={styles.dotArray2} style={{ backgroundImage: `url(${dotArrayImage})` }}></div>
            <div className={styles.headerContent}>
                <div className={styles.title}>
                    <h1>{Title} <span className={styles.secondaryText}>{SecondaryTitle}</span></h1>
                </div>
            </div>
        </div>
    );
};

export default Header;
