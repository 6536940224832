import React, { useState, useEffect } from 'react';
import styles from './ClientLogs.module.css';
import { useParams } from 'react-router-dom';
import NavBar from './NavBar'; // Import NavBar component
import { getClientById, getClientLogs } from '../api'; // Import API functions

const ClientLogs = () => {
    const { clientId } = useParams(); // Assuming clientId is passed as a URL parameter
    const [logs, setLogs] = useState([]);
    const [clientName, setClientName] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchClientData = async () => {
            try {
                const clientResponse = await getClientById(clientId);
                setClientName(clientResponse.data.client.name);

                const logsResponse = await getClientLogs(clientId);
                const logsData = Array.isArray(logsResponse.data) ? logsResponse.data : []; // Ensure logs is an array

                // Sort logs by date, most recent first
                logsData.sort((a, b) => new Date(b.date) - new Date(a.date));
                setLogs(logsData);
            } catch (error) {
                console.error('Error fetching client data:', error);
            }
        };

        fetchClientData();
    }, [clientId]);

    const filteredLogs = logs.filter(log =>
        (log.user && log.user.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (log.timestamp && log.timestamp.includes(searchTerm.toLowerCase()))
    );

    const formatDateEST = (dateString) => {
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'America/New_York',
            timeZoneName: 'short'
        };
        return new Date(dateString).toLocaleString('en-US', options);
    };

    return (
        <div>
            <NavBar /> {/* Add NavBar component */}
            <div className={styles.clientLogsContainer}>
                <h1>Client Logs for <span className={styles.secondaryTitle}>{clientName}</span></h1>
                <div className={styles.searchContainer}>
                    <input
                        type="text"
                        placeholder="Search logs by user email"
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        className={styles.searchInput}
                    />
                    <i className={`${styles.searchIcon} material-icons`}>search</i>
                </div>
                <table className={styles.logsTable}>
                    <thead>
                        <tr>
                            <th>User</th>
                            <th>Action</th>
                            <th>Timestamp</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredLogs.map(log => (
                            <tr key={log.timestamp}>
                                <td>{log.user}</td>
                                <td>{log.action}</td>
                                <td>{formatDateEST(log.date)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <p className={styles.logWarning}>*After 4 months logs get sent to cold storage. Please contact kevin.szypula@ferociousmedia.com to access</p>
            </div>
        </div>
    );
};

export default ClientLogs;
