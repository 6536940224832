// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCWQxoSBpZi80MAt3miD6zNHqGeGbGF07s",
  authDomain: "onboarding-crm-427115.firebaseapp.com",
  projectId: "onboarding-crm-427115",
  storageBucket: "onboarding-crm-427115.appspot.com",
  messagingSenderId: "765127742730",
  appId: "1:765127742730:web:39042ed116547b49b956f3",
  measurementId: "G-ZM9S9DLXYC"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

export { auth };
//const analytics = getAnalytics(firebaseApp);
//export default firebaseConfig;
