import React, { useState } from 'react';
import styles from './TabBar.module.css';

const TabBar = ({ tabs, currentTab, setCurrentTab }) => {
    return (
        <div className={styles.tabBar}>
            {tabs.map((tab, index) => (
                <div
                    key={index}
                    className={`${styles.tab} ${currentTab === index ? styles.active : ''}`}
                    onClick={() => setCurrentTab(index)}
                >
                    {tab}
                </div>
            ))}
        </div>
    );
};

export default TabBar;
