import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.css';
import logo from '../assets/logos/FerociousMEDIALogo_mark-01.png';

const Login = () => {
    const navigate = useNavigate();

    const handleAdminLogin = () => {
        navigate('/admin/login');
    };

    const handleClientLogin = () => {
        navigate('/client/login');
    };

    useEffect(() => {
        const createWave = (canvasId, maxAmplitude, length, frequency, y, x, color, lineWidth) => {
            const canvas = document.getElementById(canvasId);

            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;

            class Wave {
                constructor(
                    canv,
                    maxAmplitude,
                    length,
                    frequency,
                    y,
                    x,
                    color,
                    lineWidth
                ) {
                    this.canvas = canv;
                    this.ctx = this.canvas.getContext('2d');
                    this.maxAmplitude = maxAmplitude;
                    this.amplitude = maxAmplitude;
                    this.length = length;
                    this.frequency = frequency;
                    this.increment = 0;
                    this.y = y;
                    this.x = x;
                    this.color = color;
                    this.lineWidth = lineWidth;

                    this.frameCallback = () => {
                        this.draw(this.ctx);
                        requestAnimationFrame(this.frameCallback);
                    };
                }

                draw(c) {
                    c.clearRect(0, 0, this.canvas.width, this.canvas.height);
                    c.save();
                    c.translate(this.x, this.canvas.height / 2);
                    c.rotate(100 * Math.PI / 180);
                    c.translate(-this.canvas.height / 2, -this.canvas.width / 2);

                    c.beginPath();
                    c.strokeStyle = this.color;
                    c.lineWidth = this.lineWidth;

                    c.moveTo(0, this.y);

                    for (let i = 0; i < this.canvas.width * 2; i += 1) {
                        c.lineTo(
                            i,
                            this.y + Math.sin(i / this.length + this.increment) * this.amplitude,
                        );
                    }

                    c.stroke();
                    c.closePath();

                    c.restore();

                    this.amplitude = Math.sin(this.increment) * this.maxAmplitude;
                    this.increment += this.frequency / 1000;
                }

                animate() {
                    this.frameCallback();
                }
            }

            const wave = new Wave(canvas, maxAmplitude, length, frequency, y, x, color, lineWidth);
            wave.animate();
        };

        createWave('canvas1', 100, 140, 2, 1700, 900, '#999', 12);
        createWave('canvas2', 100, 140, 2.4, 1800, 2500, '#999', 12);

    }, []);

    return (
        <div className={styles.loginContainer}>
            <canvas id="canvas1"></canvas>
            <canvas id="canvas2"></canvas>
            <div className={styles.loginBox}>
                <img src={logo} alt="Ferocious Media" className={styles.logo} />
                <h1 className={styles.clientLoginTitle}>Welcome to Ferocious Media Onboarding</h1>
                <p>Select your login type below:</p>
                <div className={styles.loginOptions}>
                    <button className={styles.loginOption} onClick={handleAdminLogin}>
                        <h2>Employee Login</h2>
                    </button>
                    <button className={styles.loginOption} onClick={handleClientLogin}>
                        <h2>Client Login</h2>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Login;
