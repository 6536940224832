import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ferociousLogo from '../assets/logos/FerociousMEDIALogo_H.png'; // Ensure you have the correct path to the logo
import styles from './ClientNavBar.module.css';

const ClientNavBar = ({ userEmail, clientName, clientUrl, userName }) => {
    const [scrolled, setScrolled] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const userContainerRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (userContainerRef.current && !userContainerRef.current.contains(event.target)) {
                setDropdownVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [userContainerRef]);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleLogout = () => {
        localStorage.removeItem('user');
        sessionStorage.removeItem('user');
        navigate('/');
    };

    const getInitial = (name) => name ? name.charAt(0).toUpperCase() : '';

    return (
        <nav className={`${styles.clientNavBar} ${scrolled ? styles.scrolled : ''}`}>
            <div className={styles.navLeft}>
                <a href="https://ferociousmedia.com" target="_blank" rel="noopener noreferrer">
                    <img src={ferociousLogo} alt="Ferocious Media Logo" className={styles.ferociousLogo} />
                </a>
                <Link to={`/client/${clientUrl}/users`} className={styles.navLink}>
                <span className="material-symbols-outlined">add</span>Add Users

                </Link>
            </div>
            <div className={styles.navMiddle}>
            </div>
            <div className={styles.navRight}>
                <div className={styles.userContainer} ref={userContainerRef}>
                    <div className={styles.userInfo} onClick={toggleDropdown}>
                        <div className={styles.profileIcon}>{getInitial(userName)}</div>
                        <div>
                            {!scrolled && <span className={styles.clientName}>{userName}</span>}
                            <span className={styles.userEmail}>{userEmail}</span>
                        </div>
                    </div>
                    {dropdownVisible && (
                        <div className={styles.dropdownMenu}>
                            <button className={styles.logoutButton} onClick={handleLogout}>Logout</button>
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default ClientNavBar;
