// components/PrivacyPolicy.js
import React, { useEffect } from 'react';
import Footer from './Footer';

const TermsOfUse = () => {
    useEffect(() => {
        const scriptId = 'termly-jssdk';
        
        if (!document.getElementById(scriptId)) {
            const script = document.createElement('script');
            script.id = scriptId;
            script.src = "https://app.termly.io/embed-policy.min.js";
            document.body.appendChild(script);
        }
    }, []);

    return (
        <div>
            <div name="termly-embed" data-id="e97de7c3-bc17-4e55-9e42-b17c20d22c7c"></div>
            
        </div>
    );
};

export default TermsOfUse;
